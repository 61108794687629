<template>
  <main class="page page--home home">
    <section class="page__content">
      <h1>Welkom bij V-Tax Lybaert</h1>
      <p>
        100 Professionele chauffeurs en 50 voertuigen staan dag en nacht voor u
        klaar.
      </p>
      <p>
        Voor zowel bedrijven als particulieren levert V-Tax Lybaert vervoersoplossingen
        in Gent en ruime omgeving. Taxi, minibus voor 8 personen, vip-bus, limoservice met chauffeur of luchthavenservice...
      </p>
      <p>
        V-Tax Lybaert zorgt er voor dat u probleemloos op uw bestemming aankomt, lees
        meer over
        <router-link to="/services">onze diensten</router-link>
        of contacteer ons via <a href="tel:+3292222222">09/222.22.22</a>, wij
        zijn 24/7 bereikbaar.
      </p>
    </section>
    <section class="page__content home__content">
      <article class="home__content__block">
        <h2>Milieu-vriendelijke vloot</h2>
        <p>
          Wij vinden net zoals u het milieu belangrijk, vandaar dat wij constant
          bekijken hoe wij onze diensten groener kunnen maken.
        </p>
        <div
          class="home__content__block__image"
        >
          <img
            src="/album/vt14.jpg"
            alt="Elektrische taxi"
          />
        </div>
        <div class="home__content__block__controls">
          <router-link class="button" to="/about#green"
            >Meer over ons</router-link
          >
        </div>
      </article>
      <article class="home__content__block">
        <h2>Boek via de App</h2>
        <p>
          Makkelijk en snel een taxi boeken? Jazeker, download vandaag nog de
          <b>taxi.eu</b> app.
        </p>
        <div class="home__content__block__image home__content__block__image--app">
          <img src="../assets/img/taxi-eu.png" alt="Taxi-eu app" />
        </div>
        <div class="home__content__block__controls">
          <a
            target="_blank"
            href="https://play.google.com/store/apps/details?id=at.austrosoft.t4me.MB_BerlinTZBEU"
            class="button"
            >Android</a
          >
          <a
            target="_blank"
            href="https://itunes.apple.com/de/app/taxi.eu/id465315934?mt=8"
            class="button"
            >iOS (Apple)</a
          >
        </div>
      </article>
      <article class="home__content__block">
        <h2>Vervoer op maat</h2>
        <p>
          Naast onze taxi's hebben wij verschillende diensten op maat, denk
          hierbij aan minibusjes, limo-service, vip-bussen...
        </p>
        <div class="home__content__block__image">
          <img src="/taxi stadhuis.jpg" alt="Verschillende wagens aan stadhuis" />
        </div>
        <div class="home__content__block__controls">
          <router-link class="button" to="/services">Onze diensten</router-link>
        </div>
      </article>
    </section>
  </main>
</template>

<script>
export default {
  name: "Home",
};
</script>
