<template>
    <header class="header">
        <div class="header__background">
            <img class="header__background__image" src="/header.jpg" alt="Elektrische taxi's">
            <div class="header__background__content">
                <router-link class="logo" to="/"><img src="../../assets/img/v-tax-logo.png" alt="V-tax logo"></router-link>
                <div>
                    <a class="button" href="tel:092222222">Contacteer ons</a>
                    <a
            target="_blank"
            href="https://play.google.com/store/apps/details?id=at.austrosoft.t4me.MB_BerlinTZBEU"
            class="button"
            >Android App</a
          >
          <a
            target="_blank"
            href="https://itunes.apple.com/de/app/taxi.eu/id465315934?mt=8"
            class="button"
            >iOS App</a
          >
                </div>
            </div>
        </div>
        <div class="header__navigation">
            <router-link to="/">Home</router-link>
            <router-link to="/services">Diensten</router-link>
            <router-link to="/about">Over ons</router-link>
            <router-link to="/photos">Foto's</router-link>
            <router-link to="contact">Contact</router-link>
        </div>
    </header>
</template>

<script>
    export default {
        name: "TheHeader"
    }
</script>